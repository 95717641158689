import axios from 'axios'
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import router from '../router/index'
import bus from '../main'



const service = axios.create({
    // baseURL: '/api',
    baseURL: 'https://backendweb.maydot.cn',
    timeout: 30000
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 添加请求头等前置处理
        config.headers['openid'] = localStorage.getItem('openid');

        config.headers['Authorization'] = 'Bearer' + ' ' + localStorage.getItem('netToken')
        Nprogress.start()//开启进度条
        return config
    },
    error => {
        // 请求错误处理
        console.log('Request Error:', error)
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        Nprogress.done()
        // 响应后处理
        console.log(response.data, '-----------------------------------------------------------------------------------------------------')
        if (response.status === 200) {
            return Promise.resolve(response.data)
        } else {
            return Promise.reject(response.data)
        }

    },
    error => {
        console.log('Response Error:', error)
        bus.emit('showTip', {
            content: '登录过期，请重新登录！',
            type: 1,
            clearInfo: true,
            callBack: (header) => {
                header.token = ''
                router.push('/')
                localStorage.removeItem("wxToken");
                localStorage.removeItem("openid");
                localStorage.removeItem("userInfo");
                localStorage.removeItem("netToken");
                localStorage.removeItem("allinfo");
            },
            duration: 1888
        })



        Nprogress.done()


        return Promise.reject(error)
    }
)

export default service
