<template>
  <div class="outer-app-wrapper" ref="appWrapper" @touchstart="touchStart" @touchend="touchEnd" @touchmove="touchMove">
    <HeaderMaydot></HeaderMaydot>
    <div v-if="refresh">



      <router-view v-slot="{ Component }">

        <keep-alive>
          <component :is="Component" v-if="$route.meta.cache" :key="$route.name"></component>
        </keep-alive>
        <component :is="Component" v-if="!$route.meta.cache" :key="$route.name"></component>

      </router-view>


    </div>

  </div>
</template>
<script>
import HeaderMaydot from '@/components/header/header.vue'

export default {
  watch: {
    $route: {
      deep: true,
      handler() {

        this.$refs.appWrapper.style.overflowY = 'scroll'
        this.$refs.appWrapper.scrollTop = 0
        this.$nextTick(() => {
          this.$bus.emit('startWheel')
          this.$bus.emit('initAni')
        })
      }
    }
  },

  mounted() {

    window.addEventListener('wheel', wheelAni.bind(this));


    function wheelAni(e) {

      if (!this.wheelKey) return

      this.$bus.emit('onWheel', { e, ele: this.$refs.appWrapper });
    }
    this.$refs.appWrapper.addEventListener('scroll', (e) => {
      this.$bus.emit('onScroll', { e, ele: this.$refs.appWrapper, wheelKey: this.wheelKey });
    });
    this.$bus.on('backScroll', () => {
      this.wheelKey = true
      this.$refs.appWrapper.style.overflowY = 'scroll'

    })
    this.$bus.on('stopWheel', () => {
      this.wheelKey = false
      this.$refs.appWrapper.style.overflowY = 'scroll'
    })
    this.$bus.on('startWheel', () => {
      this.wheelKey = true
      this.$refs.appWrapper.style.overflowY = 'scroll'

    })
  },
  data() {
    return {
      refresh: true,
      wheelKey: true,
      startY: 0,
      endY: 0,
    }
  },
  components: {
    HeaderMaydot
  },
  methods: {
    refreshFun() {
      this.$router.push('/')
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;

      })
    },
    // touchmove(e) {
    //   this.wheelKey = true
    //   this.$bus.emit('onScroll', { e, ele: this.$refs.appWrapper, wheelKey: this.wheelKey });
    //   this.$bus.emit('onTouch', { e, ele: this.$refs.appWrapper });
    // },
    touchStart(event) {
      this.startY = event.touches[0].clientY;
    },
    touchEnd(event) {
      this.endY = event.changedTouches[0].clientY;
      this.checkDirection();
    },
    touchMove() {
      // Calculate the direction based on the start and end Y positions.  
      if (this.startY < this.endY) {
        console.log('Moving down');
      } else if (this.startY > this.endY) {
        console.log('Moving up');
      } else {
        console.log('No direction detected');
      }
    },
    checkDirection(e) {
      if (this.startY < this.endY) {
        if (!this.wheelKey) return
        this.$bus.emit('onTouch', { e, ele: this.$refs.appWrapper, upAndDown: false });
      } else if (this.startY > this.endY) {
        if (!this.wheelKey) return
        this.$bus.emit('onTouch', { e, ele: this.$refs.appWrapper, upAndDown: true });
      } else {
        console.log('no')
      }
    },
  }
}
</script>
<style lang="scss">
@font-face {
  font-family: 'md1-text';
  src: url('https://offcialnet.oss-cn-beijing.aliyuncs.com/assets/fonts/PingFangSC-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'md2-text';
  src: url('https://offcialnet.oss-cn-beijing.aliyuncs.com/assets/fonts/sf-pro-text_regular.woff2') format('woff2');
}

* {
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  overflow: hidden;
  position: relative;
}

body {
  height: 100%;
  overflow: auto;
  position: relative;
}

.active-scroll {
  overflow-y: hidden !important;


}

.outer-app-wrapper {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  // background-image: linear-gradient(125deg, #fff, #EBEDF3, #fbfbfd);
  // background-color: #fff;
  background-size: 400%;
  animation: bganimation 8.8s forwards;

}

@keyframes bganimation {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.48s;
}

.slide-left-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-left-enter-to {
  transform: translateX(0);
  opacity: 1;

}

.slide-left-leave-from {
  transform: translateX(0);
  opacity: 1;

}

.slide-top-enter-active,
.slide-top-leave-active {
  transition: all 0.48s;
}

.slide-top-enter-from {

  transform: translateY(100vh);
}

.slide-top-leave-to {

  transform: translateY(100vh);
}

.slide-top-enter-to {

  transform: translateY(0);

}

.slide-top-leave-from {

  transform: translateY(0);

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.08s;
  opacity: 1
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}












.slide-top-flex-enter-active,
.slide-top-flex-leave-active {
  transition: all 0.68s;
}

.slide-top-flex-enter-from {

  transform: translateY(20px);
}

.slide-top-flex-leave-to {

  transform: translateY(20px);
}

.slide-top-flex-enter-to {

  transform: translateY(0);

}

.slide-top-flex-leave-from {

  transform: translateY(0);

}

::-webkit-scrollbar {
  // width: 8px;
  display: none;
  /* 设置滚动条宽度 */


}

::-webkit-scrollbar-track {
  // background-color: #f1f1f1;
  border-radius: 8px;

  /* 设置滚动条背景色 */
}

::-webkit-scrollbar-thumb {
  background-color: #D2D5E3;
  /* 设置滚动条滑块颜色 */
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 设置滚动条滑块悬停时的颜色 */
}

::-webkit-scrollbar {
  width: 8px;
  /* 设置滚动条宽度 */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* 设置滚动条背景色 */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 设置滚动条滑块颜色 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 设置滚动条滑块悬停时的颜色 */
}
</style>
